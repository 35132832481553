<template>
  <div>
    <consult></consult>
    <navbar :title="content.name"></navbar>
    <div class="box">
      <div class="content">{{ content.name }}</div>
      <div class="footer">
        <img src="../assets/time.png" alt="" />
        {{ content.time }}
      </div>
      <div class="txt">
        <div v-html="content.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      content: {},
    };
  },
  methods: {
    ...mapActions([
      "gettoolDet",
      "getSignPackage",
      "getforward",
      "getnewsForward",
    ]),
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  mounted() {
    var that = this;
    var urls = window.location.href;
    if (this.userinfo) {
      var url = urls + `&invitation=${this.userinfo.invitation}`;
    } else {
      var url = urls + `&invitation=${this.invitationinif.invitation}`;
    }
    this.gettoolDet({
      id: this.$route.query.id,
    }).then((res) => {
      if (res.SuccessCode == 200) {
        // this.$dialog
        //   .confirm({
        //     title: "提示",
        //     message: "为了更好的体验请登录",
        //   })
        //   .then(() => {
        //     // on close
        //     this.$router.push("/user");
        //   })
        //   .catch(() => {});
        this.content = res.ResponseBody;
        this.$store.commit("SET_shareinformation", {
          title: this.content.name,
          imgurl: "",
          type: 3,
        });
        that
          .getSignPackage({
            url: urls,
          })
          .then((res) => {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
              timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
              signature: res.ResponseBody.signature, // 必填，签名
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });
            wx.ready(function (res) {
              wx.onMenuShareTimeline({
                title: that.content.name, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl:
                  "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg", // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  that.getnewsForward({
                    id: that.$route.query.id,
                  });
                },
              });

              wx.onMenuShareAppMessage({
                title: that.content.name, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl:
                  "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg", // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  that.getnewsForward({
                    id: that.$route.query.id,
                  });
                },
              });
            });
            wx.error(function (eer) {
              console.log(eer, 3333);
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
          });
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0.2rem;
  box-sizing: border-box;
}
.content {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.footer {
  img {
    width: 0.22rem;
    vertical-align: middle;
  }
  margin-top: 0.2rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #cdcdcd;
}
.txt {
  margin-top: 0.3rem;
  font-size: 0.28rem;
}
</style>